@import 'io-sanita-theme/theme/io-sanita/mixins';

.block.cta_block,
.block-editor-cta_block {
  .cta-block-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3.5rem 2.5rem;

    &.full-width {
      height: auto;
      padding: 70px 0;
    }

    .text-container {
      z-index: 1;
    }
    .cta-tile-text {
      width: 80%;
      margin: auto;
      text-align: center;

      h2,
      h3 {
        margin: 0 0 1.5rem;
        font-size: 2.25rem;
        color: currentColor;
      }

      p:last-of-type {
        margin-bottom: 0;
      }

      a.btn {
        position: relative;
        max-width: 300px;
        font-size: 0.9rem;

        .external-link {
          position: absolute;
          top: 3px;
          right: 3px;
        }

        &.btn-accent.inverted {
          &:not(:hover):not(:active) {
            background-color: #fff;
            color: var(--is-accent);
            .external-link {
              fill: var(--is-accent);
            }
          }
        }
      }

      @media (max-width: 600px) {
        width: 100%;
      }
    }

    &.has-image {
      position: relative;
      display: block;
      overflow: hidden;

      &:not(.has-default-image) {
        background: none;
        background: transparent !important;
        .img-wrapper,
        picture {
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            content: '';
          }
        }
      }

      &.full-width {
        display: flex;
      }

      .img-wrapper,
      picture {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        color: #fff;

        img,
        svg {
          min-width: 100%;
          min-height: 100%;
          object-fit: cover;
          object-position: center;
        }

        svg {
          height: unset !important;
          width: unset !important;
          fill: currentcolor;
          z-index: 1;
        }
      }
    }

    &.bg-primary-dark {
      color: var(--bs-primary-text);

      .cta-tile-text {
        h2,
        h3 {
          color: var(--bs-primary-text);
        }
        a.btn-outline-info {
          color: var(--bs-primary-text);
          box-shadow: inset 0 0 0 1px var(--bs-primary-text);
          &:hover,
          &:focus {
            background: var(--bs-primary-text);
            color: var(--bs-primary);
          }
          .external-link {
            fill: var(--bs-primary-text);
          }
        }
      }
    }
  }
}

@include nearFullWidthBlocks('.cta_block');
